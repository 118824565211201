import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import user from './index/user'
import mode from './index/mode'
import dict from './index/dict'
import permissionRoutes from './index/menus'

var store  = new Vuex.Store({
    modules:{
        user:user,
        mode:mode,
        dict:dict,
        menus:permissionRoutes
    }
})

export default store
