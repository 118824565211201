const mode = {
    state:{
        bool:[
            {label:'是',value:true},
            {label:'否',value:false}
        ],
        type:[
            {label:'一级菜单',value:1},
            {label:'二级菜单',value:2},
            {label:'按钮',value:3},
        ],
        color:[ //颜色
            "63, 133, 255,",
            "125, 112, 239,",
            "0, 168, 70,",
            "0, 194, 146, ",
            "0, 169, 194, ",
            "164, 194, 0,",
            "229, 139, 51,",
            "154, 112, 172,",
            "34, 102, 172,",
            "233, 69, 43,"
        ],
        barColor:['#409EFF','#67C23A','#E6A23C','#F56C6C','#909399'],
        class_eva_type:[
            {label:'视频',value:1},
            {label:'直播',value:2},
            {label:'远程视频',value:3},
        ],
        review_lists:[
            {value:1,label:'视频类指标'},
            {value:2,label:'音频类指标'},
            {value:3,label:'专家评价'},
            {value:4,label:'人机协同(视频类指标)'},
            {value:5,label:'人机协同(音频类指标)'},
        ],
    },
    mutations:{},
    actions:{}
}

export default mode