import XHR from '@/request/http'
const $h = new XHR()
// url
const url = {
    build:'/admin/v1/menus/build',//列表
    create:'/admin/v1/menus/create', //创建
    delete:'/admin/v1/menus/del', //删除
    update:'/admin/v1/menus/update', //更新
    role_lists:'/admin/v1/menus/role',//角色菜单列表
    tree:'/admin/v1/menus/tree',//权限tree
    
}

//菜单列表
export async function lists(data)
{
    return await $h.request(url.build,data,'GET')
}

//创建
export async function create(data)
{
    return await $h.request(url.create,data,'POST')
}

//删除
export async function del(data)
{
    return await $h.request(url.delete,data,'POST')
}

//更新
export async function update(data)
{
    return await $h.request(url.update,data,'POST')
}

//角色列表
export async function role(data)
{
    return await $h.request(url.role_lists,data,'GET')
}

//权限tree
export async function tree(data)
{
    return await $h.request(url.tree,data,'GET')
}
