const routes = [
    {
        path:'/expert',
        name:'expert',
        component:(async ()=>import('@/pages/admin/expert/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/expert/add',
        name:'expert_add',
        component:(async ()=>import('@/pages/admin/expert/add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/expert/edit',
        name:'expert_edit',
        component:(async ()=>import('@/pages/admin/expert/edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/module',
        name:'module',
        component:(async ()=>import('@/pages/admin/module/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/module/add',
        name:'module_add',
        component:(async ()=>import('@/pages/admin/module/add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/module/edit',
        name:'module_edit',
        component:(async ()=>import('@/pages/admin/module/edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/module/rule',
        name:'module_rule',
        component:(async ()=>import('@/pages/admin/module/rule.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/module/rule_add',
        name:'module_rule_add',
        component:(async ()=>import('@/pages/admin/module/rule_add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/module/rule_edit',
        name:'module_rule_edit',
        component:(async ()=>import('@/pages/admin/module/rule_edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/agent',
        name:'agent',
        component:(async ()=>import('@/pages/admin/agent/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/agent/add',
        name:'agent_add',
        component:(async ()=>import('@/pages/admin/agent/add.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/agent/edit',
        name:'agent_edit',
        component:(async ()=>import('@/pages/admin/agent/edit.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/college',
        name:'college',
        component:(async ()=>import('@/pages/admin/college/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/college/add',
        name:'college_add',
        component:(async ()=>import('@/pages/admin/college/add.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/college/edit',
        name:'college_edit',
        component:(async ()=>import('@/pages/admin/college/edit.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/user_manager',
        name:'user_manager',
        component:(async ()=>import('@/pages/admin/usermanager/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/user_manager/create',
        name:'user_manager_create',
        component:(async ()=>import('@/pages/admin/usermanager/add.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/user_manager/update',
        name:'user_manager_update',
        component:(async ()=>import('@/pages/admin/usermanager/edit.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/auto',
        name:'auto',
        component:(async ()=>import('@/pages/admin/auto/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/auto/add',
        name:'auto_add',
        component:(async ()=>import('@/pages/admin/auto/add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/auto/edit',
        name:'auto_edit',
        component:(async ()=>import('@/pages/admin/auto/edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/subject',
        name:'subject',
        component:(async ()=>import('@/pages/admin/subject/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/subject/add',
        name:'subject_add',
        component:(async ()=>import('@/pages/admin/subject/add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/subject/edit',
        name:'subject_edit',
        component:(async ()=>import('@/pages/admin/subject/edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/teacher',
        name:'teacher',
        component:(async ()=>import('@/pages/admin/teacher/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/teacher/add',
        name:'teacher_add',
        component:(async ()=>import('@/pages/admin/teacher/add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/teacher/edit',
        name:'teacher_edit',
        component:(async ()=>import('@/pages/admin/teacher/edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/source',
        name:'source',
        component:(async ()=>import('@/pages/admin/source/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/source/add',
        name:'source_add',
        component:(async ()=>import('@/pages/admin/source/add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/source/edit',
        name:'source_edit',
        component:(async ()=>import('@/pages/admin/source/edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/user',
        name:'user',
        component:(async ()=>import('@/pages/admin/user/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/user/add',
        name:'user_add',
        component:(async ()=>import('@/pages/admin/user/add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/user/edit',
        name:'user_edit',
        component:(async ()=>import('@/pages/admin/user/edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/role',
        name:'role',
        component:(async ()=>import('@/pages/admin/role/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/role/add',
        name:'role_add',
        component:(async ()=>import('@/pages/admin/role/add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/role/edit',
        name:'role_edit',
        component:(async ()=>import('@/pages/admin/role/edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/role/auth',
        name:'role_auth',
        component:(async ()=>import('@/pages/admin/role/auth.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/dict',
        name:'dict',
        component:(async ()=>import('@/pages/admin/dict/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/dict/add',
        name:'dict_add',
        component:(async ()=>import('@/pages/admin/dict/add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/dict/edit',
        name:'dict_edit',
        component:(async ()=>import('@/pages/admin/dict/edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/dict_type',
        name:'dict_type',
        component:(async ()=>import('@/pages/admin/dict/type.vue')),
        meta:{title:'',menu:false},
    },
    {
        path:'/dict_type/add',
        name:'dict_type_add',
        component:(async ()=>import('@/pages/admin/dict/type_add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/dict_type/edit',
        name:'dict_type_edit',
        component:(async ()=>import('@/pages/admin/dict/type_edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/menus',
        name:'menus',
        component:(async ()=>import('@/pages/admin/menus/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/menus/add',
        name:'menus_add',
        component:(async ()=>import('@/pages/admin/menus/add.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/menus/edit',
        name:'menus_edit',
        component:(async ()=>import('@/pages/admin/menus/edit.vue')),
        meta:{title:'',menu:false}
    },
    {
        path:'/model', //模型
        name:'model',
        component:(async ()=>import('@/pages/admin/model/index.vue')),
        meta:{title:'',menu:true},
    },
    {
        path:'/model/add', //模型
        name:'model_add',
        component:(async ()=>import('@/pages/admin/model/add.vue')),
        meta:{title:'',menu:false},
    },
    {
        path:'/model/edit', //模型
        name:'model_edit',
        component:(async ()=>import('@/pages/admin/model/edit.vue')),
        meta:{title:'',menu:false},
    },

    {
        path:'/screen/report', //专家评课
        name:'screen_report',
        component:(async ()=>import('@/pages/screen/report.vue')),
        meta:{title:'',menu:true},
    },
    

];
import {lists} from '@/request/api/menus'

const permissionRoutes = {
    state:{
        permissionRoutes:routes,
        roleRoutes:[],
        routerName:[], //所有权限的name
    },
    mutations:{
        setRoute(state,payload){
            state.routerName = payload
        },
        setRoleRoutes(state,payload){
            state.roleRoutes = payload
        },
    },
    actions:{
        async getMenus({commit}){
            let {code,data} = await lists({})
            let d = (code==0) ? data.menus : []
            commit('setRoleRoutes',d)
            return d
        },
        setRouteName({commit},payload) {
            commit('setRoute',payload)
        }
    }   
}

export default permissionRoutes