import Vue from 'vue'
import router from './router/router'
import store from './store/store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'tcplayer.js/dist/tcplayer.min.css';
Vue.use(ElementUI)
import App from './App.vue'
import getPdf from './global/getpdf.js'
Vue.use(getPdf)
//自定义滚动条 overlayscrollbars
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue';
// global registration
Vue.component('overlay-scrollbars', OverlayScrollbarsComponent);

Vue.config.productionTip = false
// 注册全局请求库
Vue.prototype.$win = window
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')