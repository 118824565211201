import axios from 'axios'
import store from '@/store/store'
import router from '@/router/router'

class XHR
{ 
    // 初始化
    constructor(config){       
        this.config = {
            baseURL:window.apiUrl.baseURL || process.env.VUE_APP_BASE_URL,
            headers:{
                // "Content-Type":"application/x-www-form-urlencoded;charset=utf8"
                "Content-Type":"application/json"
            },
            timeout:10000,
            crossDomain:true
        }

        // 创建axios 实例
        this.config = Object.assign(this.config ,config)
        this.$request = axios.create(this.config)

        // 请求拦截器
        this.$request.interceptors.request.use(config => {
            //携带token
            if(store.state.user.token) {
                config.headers['Authorization'] = store.state.user.token
            }
            return config ;
        },(err)=>{
            return Promise.reject(err)
        })
        
        // 响应拦截器
        this.$request.interceptors.response.use(config => {
            // 对响应做处理
            // 登录失效
            if(config.data.code==401) {
                store.commit('logout')   
                router.push('/login')
            }
            
            return config;
        },err => {
            var httpStatus = err.response.status
            if(httpStatus == 401) {
                store.commit('logout')   
                router.push({name:'login'})
            }
            return Promise.reject(err)
        })

        
    }

    request(url ,data ,method="GET"){
        if(typeof data === 'string') {
            method = data
            data = {}
        }

        const config = {
            url ,
            method
        }

        // get和post请求
        if(method == 'GET') {
            config.params = data
        }else{
            // config.data = qs.stringify(data)
            config.data = data
        }
        // 发起请求
        return new Promise((resolve ,reject) => {
            this.$request(config).then(({data})=>{
                return resolve(data);
            }).catch(err=>{
                err = err && err.toString()
                // console.error(err)
                return reject(err);
            })
        })

    }

    get(url ,data){
        return this.request(url ,data ,'GET')
    }

    post(url ,data){
        return this.request(url ,data ,'POST')
    }
    
    postStream(url,data){
        return axios({
            url:url,
            data:data,
            method:'POST',
            responseType: 'stream',
            baseURL:window.apiUrl.baseURL || process.env.VUE_APP_BASE_URL,
            headers:{
                // "Content-Type":"application/x-www-form-urlencoded;charset=utf8"
                "Content-Type":"application/json",
                "Authorization":store.state.user.token
            },
            // timeout:10000,
            crossDomain:true
        })
    }
}

export default XHR