var userinfo = window.sessionStorage.getItem('userinfo');
userinfo = userinfo ? JSON.parse(userinfo) : {};
var user = {
    state:{
        token:userinfo.token?userinfo.token:'', //token
        username:userinfo.username?userinfo.username:'',//用户名
        id:userinfo.id?userinfo.id:'',
    },
    mutations:{
        //登录
        login(state,payload){
            if(!payload) return;
            state.token = payload.token
            state.username = payload.username
            state.id = payload.id
            window.sessionStorage.setItem('userinfo',JSON.stringify(payload))
        },
        //退登
        logout(state){
            state.token = '',
            state.username = '',
            state.id = '';
            window.sessionStorage.removeItem('userinfo')
        }
    },
    actions:{}
}

export default user
