import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import store from '@/store/store'
const mconfig = window.mconfig
Vue.use(VueRouter)
var routername = ['login','nofound','home','report']; //白名单
const commonRoutes = [
    {
        path:'/login',
        name:'login',
        component:(async ()=>import('@/pages/login.vue')),
        meta:{title:'登录'},
    },
    {
        path:'/nofound',
        name:'nofound',
        component:(async ()=>import('@/pages/nofound.vue')),
        meta:{title:'404页面'},
    },
    {
        path:'/',
        name:'admin',
        component:(async ()=>import('@/pages/index.vue')),
        redirect:'/home',
        meta:{title:'主页'},
        children:[
            {
                path:'/home',
                name:'home',
                component:(async ()=>import('@/pages/admin/home/index.vue')),
                meta:{title:mconfig.welcome,menu:true}
            },
            
        ]
    },
    {
        path:'/screen', //专家评课
        name:'screen',
        component:(async ()=>import('@/pages/screen/index.vue')),
        meta:{title:'',menu:true},
        children:[
            {
                path:'/screen/report', //报告
                name:'screen_report',
                component:(async ()=>import('@/pages/screen/report.vue')),
                meta:{title:'',menu:true},
            },
            {
                path:'/screen/teacher_portrait', //教师画像
                name:'screen_teacher',
                component:(async ()=>import('@/pages/screen/teacher_portrait.vue')),
                meta:{title:'',menu:true},
            },
            {
                path:'/screen/class_portrait', //课程画像
                name:'screen_class',
                component:(async ()=>import('@/pages/screen/class_portrait.vue')),
                meta:{title:'',menu:true},
            },
            {
                path:'/screen/lesson', //lesson课程
                name:'screen_lesson',
                component:(async ()=>import('@/pages/screen/lesson.vue')),
                meta:{title:'',menu:true},
            },
        ]
    },
    {
        path:'/report',
        name:'report',
        component:(async ()=>import('@/pages/report/index.vue')),
        meta:{title:'报告'},
    },
];


const router = new VueRouter({
    routes:commonRoutes
})

async function setRoutes(){
    let url = window.apiUrl.baseUrl || process.env.VUE_APP_BASE_URL
    let permissionRoutes = store.state.menus.permissionRoutes
    let req = axios.create({
        baseURL:url,
        timeout:5000,
        headers:{
            'Authorization':store.state.user.token
        }
    })
    let {data} = await req.get('/admin/v1/menus/build')
    if(data.code!=0) return;
    data.data.menus.forEach(res=>{
        let obj = permissionRoutes.find(f=>f.name==res.path)
        //一级菜单
        if(!res.children) {
            //只有一级
            obj && (obj['meta']['title'] = res.title)
            obj && router.addRoute('admin',obj)
        }else{
            res.children.forEach(two=>{
                let two_obj = permissionRoutes.find(f=>f.name==two.path)
                //二级
                if(two_obj) {
                    var _two = {...two_obj,children:[]}
                    _two['meta']['title'] = two.title || ''
                    router.addRoute('admin',_two)
                }
                if(two.children) {
                    //按钮
                    two.children.forEach(three=>{
                        let three_obj = permissionRoutes.find(f=>f.name==three.path)
                        three_obj && (three_obj['meta']['title'] = three.title||'')
                        three_obj && router.addRoute(two.path,three_obj)
                    })
                }
            })
        }
    })
    return data.data
}
const data = setRoutes()


router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title == undefined?'':to.meta.title;
    
    //不需要验证登录的路由放行
    if(routername.includes(to.name)) {
        return next()
    }

    //没有token
    if(!store.state.user.token) {
        return next({name:'login'})
    }

    //没有name的路由
    // if(!to.name) {
    //     return next({name:'nofound'})
    // }
    
    //没有权限
    let arr = [];
    data.then(res=>{
        res.menus.forEach(item=>{
            if(item.path && !arr.includes(item.path)) {
                arr.push(item.path)
            }
            item.children && item.children.forEach(itx=>{
                if(itx.path && !arr.includes(itx.path)) {
                    arr.push(itx.path)
                }
            })
        })
        res.buttons.forEach(item=>{
            if(item.path && !arr.includes(item.path)) {
                arr.push(item.path)
            }
        })
        store.dispatch('setRouteName',arr)
        if(to.name && !arr.includes(to.name)) {
            return next({name:'nofound'})
        }
        return next()
    })
    return next()
})

export default router;