<template>
  <router-view></router-view>
</template>

<script>
export default {
   
}
</script>

<style lang="less">
// 公共模板
@import url('@/assets/css/common.less');

// 工具类
@import url('@/assets/css/main.less');
@sider_w:200px; //侧边导航宽度
@sider_bg-color:#191A23; //侧边导航背景颜色


</style>
